import logo from './logo.svg';
import './App.css';

function App() {
  return (

    <div className="content">
      <div className="header">
        <img className="LOGO" src={require("./scieduLOGO2.jpg")} ></img><h1>科普計畫網站</h1>

      </div>
      <div className="tableA">
        <table className="table1">
          <tr className="tr1">
            <td className="td1">
              <div className="image-container">
                <a href="http://oceaniot.iitmlab.tw/">
                  <img className="oceanIOT" src={require("./market2.jpg")} ></img>
                  <div className="text-overlay">進入網站</div>
                </a>
              </div>

            </td>
            <td className="td2"><h1 className='td2-h1'>想享科技翩翩起舞，特色偏鄉教育扎根</h1>
              <h3 className='td2-h3'>由國立高雄科技大學所執行國科會之科普計畫<br />計畫名稱：「科普活動：想享科技翩翩起舞，特色偏鄉教育扎根」。
                <br />
                <br />
                計畫內容包含以下幾項：
                讓民眾了解海洋及氣候變遷相關原理及科普知識，包含：海洋生物、潮汐、海洋汙染、氣候變遷、海岸地形與特色、海洋保育。
                讓民眾了解海洋中的奧妙及氣候變遷對大自然的改變等。<h3 className="number">計畫編號:NSTC112-2515-S-992 -003</h3><br /><a className="href1" href="http://oceaniot.iitmlab.tw/">更多內容...</a>
              </h3></td>
          </tr>


          <tr className="tr2" >
            <td className="td1">
              <div className="image-container">
                <a href="http://cubiio.iitmlab.tw/">
                  <img className="cubiio" src={require("./cubiio.jpg")} ></img>
                  <div className="text-overlay">進入網站</div>
                </a>
              </div>
            </td>
            <td className="td2">
              <h1  className='td2-h1'>刻劃「藝陣之鄉」，科學內門逍遙遊</h1>
              <h3 className='td2-h3'>由國立高雄科技大學及正修科技大學，兩所學校一起執行國科會的科普計畫團隊，<br />計畫名稱：「科普活動：刻劃「藝陣之鄉」，科學內門逍遙遊，Cubiio 酷斃了」。
                <br />
                <br />
                計畫內容包含以下幾項：
                讓民眾了解雷射雕刻相關的科學原理及科普知識，包含：光、顏色、可視光、雷射與普通光的差異、雷射的原理、雷射的種類、波長特性、雷射的震盪原理、雷射振盪器的結構。
                讓民眾了解雷射雕刻機的種類、材質應用、切割種類等。
                <h3 className="number">計畫編號:NSTC 111-2515-S-230 -001</h3>
                <br />
                <a className="href1" href="http://cubiio.iitmlab.tw/">
                  更多內容...
                </a>
              </h3>
            </td>
          </tr>
        </table>
      </div>
      <div className="footer">
        <img className="footer" src={require("./NKUST.png")} ></img>
        <img className="footer" src={require("./NSTC.png")} ></img>
        <img className="footer" src={require("./IYBSS.jpg")} ></img>
      </div>
    </div>
  );
}

export default App;
